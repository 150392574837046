import React from "react";
import qrCode from "../assets/images/QR/send_msg.svg";

const Modal = (props) => {
  const btn_text = props.btn_text ? props.btn_text : "Book A Call";

  return (
    <>
      <button
        type="button"
        className="call_btn my-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {btn_text}
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h3 className="modal-title fs-5" id="exampleModalLabel">
                Scan the QR code to book a call.
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img src={qrCode} alt="Send Message" />
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
