import React from "react";
import { FaInstagram } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="contact_page mt-5 pt-5">
      <div className="hero_section even_bg py-5">
        <div className="container">
          <h1>
            “Get in Touch:{" "}
            <span className="single_element">
              Let's Brew Up Some Conversation!”
            </span>
          </h1>
          <p>
            <div className="single_element">
              <strong>Brewing Connections, One Cup at a Time!</strong>
            </div>
            We're thrilled to connect with fellow tea enthusiasts like you!
            Whether you have questions about our menu, want to inquire about
            franchise opportunities, or simply wish to share your love for tea,
            we're here to listen. Reach out to us using the form below, and
            let's brew up some delightful conversations together. Your feedback,
            queries, and tea tales are always welcome. Cheers to building a
            community united by the love of chai!
          </p>
        </div>
      </div>
      <div className="contact container py-5">
        <div className="office_map">
          <iframe
            title="Office Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.05155080701!2d74.47578157511694!3d19.7104292816292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc5957d774cd45%3A0xb95a39913df1fec9!2sGraduate%20chai%20office!5e0!3m2!1sen!2sin!4v1713845370576!5m2!1sen!2sin"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="socials mt-5">
          <div className="row text-center">
            <div className="col-md-4 p-3">
              <div className="social_icon">
                <FaInstagram /> <strong>Instagram</strong>
              </div>
              <p className="mt-3 text-center">
                <a href="https://www.instagram.com/graduate_chai_/">
                  instagram.com/graduate_chai_/
                </a>
              </p>
            </div>
            <div className="col-md-4 p-3">
              <div className="social_icon">
                <IoCall /> <strong>Phone</strong>
              </div>
              <p className="mt-3 text-center">
                <a href="call:9730636550">9730636550</a>
              </p>
            </div>
            <div className="col-md-4 p-3">
              <div className="social_icon">
                <FaYoutube /> <strong>Youtube</strong>
              </div>
              <p className="mt-3 text-center">
                <a href="https://www.youtube.com/@graduate_chai_">
                  youtube.com/@graduate_chai_
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
