import React from "react";
import our_team from "../assets/images/our_team.svg";
import Modal from "../components/Modal";

const About = () => {
  return (
    <div className="about_page">
      <div className="hero_section even_bg py-5">
        <div className="container">
          <h1>
            “Discover Our Essence: Unveiling{" "}
            <span className="single_element">Graduate Chai!”</span>
          </h1>
          <p>
            Rooted in a humble village,{" "}
            <span className="single_element">Graduate Chai</span> sprouted from
            the dreams of a small community. From a modest beginning, we've
            blossomed into a flourishing tree, symbolizing resilience and
            growth. Our journey reflects the transformation of a simple tea
            stall into a thriving business, spreading the warmth of our roots to
            communities across India. Join us in celebrating this extraordinary
            tale, where every sip tells the story of perseverance and success.
          </p>
        </div>
      </div>
      <div className="about_content py-5 container">
        <p>
          Belonging to a Poor and labourer simple Maharashtrian family, We
          always wanted to help our families financially. We tried every
          possible thing to earn money and respect in society. Long story short,
          we tried every possible thing from our college days to earn money,
          like working in milk dairy, farming, network marketing, food stalls,
          and the milk business & what not. But couldn’t generate that level of
          income our life situation was asking for and there was no work
          satisfaction also.
        </p>
        <p>
          To do something unique, different and problem solving. So we started
          searching online for Ideas and came to know that everything was being
          sold online except TEA. And “Eureka- we decided to launch our tea
          business with an online ordering system” as we were all Graduates so
          we decided to name it,{" "}
          <span className="single_element">
            “GRADUATE CHAI” (THE EDUCATED TASTE).
          </span>
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 text-center">
            <img src={our_team} alt="" className="our_team_illustration" />
          </div>
          <div className="col-lg-8 col-md-6">
            <p>
              All Relatives and People in Society started laughing at us that
              now Graduates will sell Chai(Tea), our family was not ready,
              people were laughing, but we had faith in ourselves so we launched
              our very First Outlet at Shirdi- The Holy place of Shri Sai baba.
              Soon our unique taste of tea became a favorite of many People and
              people started getting attracted to us.
            </p>
            <p>
              For 3 years we had a Single outlet. Lot of problems we were facing
              & the main problem was a total of 2 years of corona lockdown. But
              by the power of patience we never gave up and here we get great
              fruit, now we are at a total 130+ franchise and still counting.
              Graduate chai expanded out of maharashtra also & we are getting
              love from there also. Being from a simple labourer family and
              becoming successful in an entrepreneurship journey is just like a
              miracle to us.
            </p>
          </div>
        </div>

        <p>
          <strong className="single_element">
            If you are also connected with this story, you want to grow in life,
            want to do something unique, want to follow passion, want to change
            the financial status of family then, Lets Get hands together Join
            the fastest growing, affordable, successful Tea business, The
            “Graduate Chai” steal our experience, practical Knowledge, support
            and what not. And make your dreams come true.
          </strong>
        </p>
        <div className="text-center">
          <Modal />
        </div>
      </div>
      <div className="our_team even_bg py-5">
        <div className="container">
          <h1>
            The Hero's behind the Success!{" "}
            <span className="single_element">Meet Our Team</span>
          </h1>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="slogan">
                “The way a team plays as a whole determines its success. You may
                have the greatest bunch of individual stars in the world, but if
                they don’t play together, the club won’t be worth a dime.”
                <div className="mt-3">- Babe Ruth</div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Nilesh Jadhav - Founder & CEO</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Vishal Gadhave - Founder & CLO</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Nitin Kolekar - CFO</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Kiran Mali - COO</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Sagar Gadhave - Food Trainer</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Adesh Petare - Field Manager</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Abhishek Telore - Operational Head</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Akshay Varpe - Growth Manager</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Akshay Shinde - Sales Manager</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Kunal Kasar - Marketing Head</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Pratik Khilari - Digital Marketing Head</strong>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="team_member_card">
                    <strong>Nilesh Jadhav - Founder & CEO</strong>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
