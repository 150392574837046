import React from "react";
import Map from "../assets/images/map.svg";
import Insights from "./Insights";
import Modal from "./Modal";

const HomeFranchiseSection = () => {
  return (
    <>
      <div className="homepage_franchise_section py-5 px-5">
        <div className="row">
          <div className="col-md-5">
            <div className="section_image text-center">
              <img src={Map} alt="" />
            </div>
          </div>
          <div className="col-md-7">
            <h1>
              {/* Spreading the Aroma of Authentic{" "}
            <span className="single_element">Graduate Chai</span> Across India! */}
              A Network of <span className="single_element">Happiness!</span>
            </h1>
            <p>
              At <span className="single_element">Graduate Chai</span>, we take
              immense pride in our journey of bringing the rich and aromatic
              flavors of tea to every nook and corner of India. What started as
              a humble tea stall in Shirdi has now evolved into a thriving
              franchise network that spans the length and breadth of the
              country.
            </p>
            <Insights />
          </div>
        </div>
      </div>
      <div className="home_franchise_action py-5 text-center">
        <div className="container">
          <h1>
            Let's Join hands together and you may be the next franchise owner of{" "}
            <span className="single_element">Graduate Chai</span> in your town!
          </h1>
          <div>
            <Modal />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFranchiseSection;
