import React from "react";
import Logo from "../assets/images/logo.png";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="footer_brand_logo">
                <img src={Logo} alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer_links mb-3">
              <h4>Navigation</h4>
              <ul className="mt-3">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/locations">Locations</a>
                </li>
                <li>
                  <a href="/franchise">Franchise</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer_links mb-3">
              <h4>Products</h4>
              <ul className="mt-3">
                <li>Tea</li>
                <li>Lassi</li>
                <li>Pohe</li>
                <li>Snacks</li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer_links mb-3">
              <h4>Contact</h4>
              <ul className="mt-3">
                <li>
                  <FaMapMarkerAlt /> <strong>Address: </strong>
                  <br />
                  PF6H+58H,
                  <br />
                  Rahata, Maharashtra 423107
                </li>
                <li className="mt-2">
                  <FiPhoneCall /> <strong>Phone: </strong>9730636550
                </li>
                <li className="mt-2">
                  <FiMail /> <strong>Email: </strong>support@graduate.in
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
