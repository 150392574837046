import React, { useEffect, useState } from "react";
import brand_logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [shadow, setShadow] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 500) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };
  }, []);

  return (
    <nav
      className={
        shadow
          ? "navbar fixed-top navbar-expand-lg navbar-light nav-shadow"
          : "navbar fixed-top navbar-expand-lg navbar-light"
      }
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={brand_logo} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center py-md-3"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/about-us">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/locations">
                Locations
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/franchise">
                Franchise
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
