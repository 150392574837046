import React from "react";
import Modal from "../components/Modal";
import Marquee from "react-fast-marquee";
import YTCard from "../components/YTCard";

const videos = [
  {
    url: "https://www.youtube.com/embed/cjAUviwVm1M?si=cvUhAjIU5KLWq6B2&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/PTWvki3-hhE?si=FfzBR8mx9kn3oYEI&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/NvmI9KkRywk?si=RIqUXB4GOHHOTngD&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/Kd6Fjr3L8g4?si=ykMBjabeEgGYhoCa&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/m5e2cl_jN0k?si=gzlMy8v6nfl5xOoQ&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/eFcdl__eLIg?si=Tyx4nWUecCTxLCxk&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/fF9kfZEQRVY?si=REbw5bqWeRYGKycf&amp;controls=0",
  },
  {
    url: "https://www.youtube.com/embed/rP7QuktCbQ4?si=sGIIeMXDnUfOyANk&amp;controls=0",
  },
];

const Franchise = () => {
  return (
    <div className="franchise_page pt-5">
      <div className="hero_section even_bg py-5 mt-5">
        <div className="container">
          <h1 className="py-3">
            “Exploring India's Tea Trail:{" "}
            <span className="single_element">Our Franchise Network!”</span>
          </h1>
          <p>
            Are you passionate about the rich tradition of chai and eager to
            embark on an entrepreneurial journey? Look no further! At{" "}
            <span className="single_element">Graduate Chai</span>, we're
            thrilled to offer you the opportunity to own a piece of India's tea
            culture with our franchise network spread across the nation.
          </p>
          <p>
            Join us in our mission to bring the authentic flavors of chai to
            every corner of India. With our proven business model, comprehensive
            support system, and a brand that resonates with tea enthusiasts
            nationwide, owning a franchise with us is not just a business
            venture; it's a chance to be part of a thriving community and a
            beloved brand.
          </p>
          <p>
            Experience the satisfaction of being your own boss while making a
            meaningful impact in your community. Whether you're an experienced
            entrepreneur or a first-time business owner, we provide the tools,
            training, and ongoing support to help you succeed.
          </p>
          <p>
            Take the first step towards owning your own tea stall franchise and
            become part of our growing family today!
          </p>
          <div className="text-center">
            <Modal btn_text="Be A Franchise Owner!" />
          </div>
        </div>
      </div>
      <div className="franchise_reviews container py-5">
        <h1>What Our Franchise Owner's Say About Us!</h1>
        <div className="reviews py-5">
          <Marquee>
            {videos.map((vid, idx) => {
              return <YTCard url={vid.url} key={idx} />;
            })}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Franchise;
