import React from "react";

const YTCard = (props) => {
  let url = props.url;

  return (
    <div className="reviews_card mx-5">
      <div className="card">
        <div className="card-body">
          <p className="card-text">
            <iframe
              width="400"
              height="230"
              src={url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </p>
        </div>
      </div>
    </div>
  );
};

export default YTCard;
