import React from "react";

const LocationCard = (props) => {
  let loc = props.loc;

  return (
    <div className="col-xs-6 col-md-2 text-center">
      <div className="loc_card px-3 py-2 mt-2 ">
        <h5>{loc}</h5>
      </div>
    </div>
  );
};

export default LocationCard;
