import React from "react";
import Slide1 from "../assets/images/slide1.png";
import HomeFranchiseSection from "../components/HomeFranchiseSection";
import HomeAboutSection from "../components/HomeAboutSection";
import HomeFounderSection from "../components/HomeFounderSection";
import HomeTestimonialsSection from "../components/HomeTestimonialsSection";

const Home = () => {
  return (
    <>
      <div className="homepage">
        <div className="row hero_section">
          <div className="col-md-8 text-center">
            <div className="slide-title">
              <h1 className="px-5">
                Engage <br />
                With The Sweetest Cup of Tea in India...
              </h1>
              <h1 className="brand-name">Graduate Chai!</h1>
              <div className="slogan">The Educated Taste!</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="slide-image text-center">
              <img src={Slide1} alt="" />
            </div>
          </div>
        </div>
        <HomeAboutSection />
        <HomeFounderSection />
        <HomeFranchiseSection />
        <HomeTestimonialsSection />
      </div>
    </>
  );
};

export default Home;
