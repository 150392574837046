import React from "react";
import Marquee from "react-fast-marquee";
import TestimonialCard from "./TestimonialCard";

const reviews = [
  {
    name: "Gaurav Rasal",
    position: "Developer, Pune",
    review: `Most of the developer's are addicted to coffee but I am a regular
            consumer of "Graduate Chaha". Whenever the bugs trouble me(in the
            code) I take a cup of "Ginger Tea" and that help me to solve the
            bugs.`,
  },
  {
    name: "Pratik Khilari",
    position: "Digital Maketer, Rahuri",
    review: `Absolutely love the chai here! It's the perfect blend of spices and warmth, 
    just like homemade chai. Can't start my day without a cup from Graduate Chai!`,
  },
  {
    name: "Vijay Shinde",
    position: "Student, Loni",
    review: `I'm a regular at Graduate Chai, and for good reason! The quality of their tea is unmatched, 
    and the staff is always friendly and welcoming. Highly recommend trying their Graduate Special Chai!`,
  },
  {
    name: "Shubham Khemnar",
    position: "Entrepreneur, Sangamner",
    review: `As a tea aficionado, I'm always on the lookout for authentic chai experiences. 
    Graduate Chai never disappoints! Their Masala Chai is rich, flavorful, and always hits the spot.`,
  },
  {
    name: "Siddhu Lukkad",
    position: "Contractor, Pune",
    review: `eing a tea lover, I'm quite picky about where I get my chai from. Graduate Chai has 
    become my go-to spot for a comforting cup of tea. Their Elaichi Chai is a true masterpiece!`,
  },
];

const HomeTestimonialsSection = () => {
  return (
    <div className="homepage_testimonials even_bg py-5 text-center">
      <div>
        <h1>
          What <span className="single_element">Consumer's</span> Say About Us
        </h1>
      </div>
      <div className="my-5">
        <Marquee>
          {reviews.map((rev, idx) => {
            return <TestimonialCard rev={rev} key={idx} />;
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default HomeTestimonialsSection;
