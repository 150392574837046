import React from "react";

const HomeFounderSection = () => {
  return (
    <div className="homepage_founder_section py-5 px-5 even_bg">
      <div className="row">
        <div className="col-md-7">
          <h1>
            Meet the <span className="single_element">Mastermind</span>
          </h1>
          <div className="founder_name">Mr. Nilesh Jadhav</div>
          <p>
            Meet the visionary behind{" "}
            <span className="single_element">
              Graduate Chai, Mr. Nilesh Jadhav
            </span>
            . With an unwavering passion for tea and a dream to redefine India's
            chai culture, <span className="single_element">Mr. Nilesh </span>
            embarked on this flavorful journey. Inspired by the diverse regional
            tea traditions, they envisioned creating a brand that not only
            served exceptional chai but also became a cultural bridge across the
            nation. <span className="single_element">Mr. Nilesh's</span>{" "}
            commitment to quality, innovation, and community engagement has been
            the driving force behind the success of{" "}
            <span className="single_element">Graduate Chai</span>, making it a
            cherished destination for tea enthusiasts nationwide. Join us in
            raising a cup to the founder's dedication, shaping a legacy steeped
            in aromatic tales and flavorful experiences.
          </p>
        </div>
        <div className="col-md-5">
          <div className="section_image mt-md-5 text-center">
            <iframe
              src="https://www.youtube.com/embed/A3Sq8VmElX8?si=o90KJlOqyVcEDnlH&amp;controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFounderSection;
