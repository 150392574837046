import React from "react";

const TestimonialCard = (props) => {
  let { name, position, review } = props.rev;

  return (
    <div className="testimonial_card mx-5">
      <div className="card p-3">
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <h6 className="card-subtitle mb-2 text-body-secondary">{position}</h6>
          <p className="card-text mt-3">"{review}"</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
