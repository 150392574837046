import React from "react";
import LocationCard from "../components/LocationCard";

let locations = [
  "Shirdi",
  "Shrirampur",
  "Kukana",
  "Ahmednagar",
  "Kopargaon",
  "Rahata",
  "Loni",
  "Yeola",
  "Sangamner",
  "Chikhali",
  "Parner",
  "Gangapur",
  "Malegaon",
  "Sarangkheda",
  "Babhaleshwar",
  "Chalisgaon",
  "Sinnar",
  "Mumbai",
  "Kolhapur",
  "Supa",
  "Mungase",
  "Narayangaon",
  "Kalewadi Phata",
  "Sambhajinagar",
  "Hadapsar",
  "Samsherpur",
  "Nandurbar",
  "Ranjangaon",
  "DK Malegaon",
  "Shahada",
  "Vaijapur",
  "Mahalgaon",
  "Jalna",
  "Old Kaigaon",
  "Dhule",
  "Karad",
  "Satara",
  "Shirpur",
  "Mohdari",
  "BajajNagar",
  "Surat",
  "Kudashi",
  "Dhawade",
  "Pimpalner",
  "Washim",
  "Lasoor",
  "Panchale",
  "Sayan",
  "Daman",
  "Kharadi",
  "Ambegaon",
  "Dhoregaon",
  "Newasa",
  "Navsari",
  "Dadh Budruk",
  "Ridhore, Akola",
  "Walunj",
  "Taharabad",
  "Ichalkaranji",
  "Sakari",
  "Puntamba",
  "Navapur, Beed",
  "Jolwa, Gujarat",
  "Rahuri",
  "Nanded",
  "Mular",
  "Shikrapur",
  "Alibaug",
  "Bidkin",
  "Rahuri Factory",
  "Palghar",
  "Buldhana",
  "Kasare",
  "Nampur",
  "Manmad",
  "Alephata",
  "Paithan",
  "Kundewadi",
  "Devagaon road",
  "Wardha",
  "Bhendala",
  "Jalgaon",
  "Rajasthan",
  "Ghodegaon",
  "Dabhadi",
  "Sulga",
  "Savangi",
  "Nanded",
  "Shindkheda",
  "Delhi",
  "Manchar",
  "Karanjad",
  "Wagholi",
  "Bamrub",
  "Chikhalthana",
  "Dehu",
  "Bhandgaon",
  "Shrivardhan",
  "New Mumbai",
  "Sajapur",
  "FC road, Pune",
  "Tadoba Park",
  "Kolkata",
  "Sonai",
  "Parbhani",
  "Dhodi BK",
  "Dhatrak Phata",
  "Old Kaigaon",
  "Aurangpura",
  "Moshi",
  "Shewali Phata",
  "Yavatmal",
  "Kolhapur",
  "Pandhurli",
  "Behed",
  "Nagpur",
  "Talegaon",
  "Kalvan",
  "Amaravati",
  "Javale Bazar",
  "Kumbharwada",
  "Shevgaon",
  "Mhasadi",
  "Wavi",
  "Bhiwandi",
  "Mulegaon",
  "Ravalgaon",
  "Honga, Belgaon",
  "Kachgaon",
  "Dehu Road",
  "Virgaon",
  "Palam",
  "Pandharpawda",
  "Gondegaon",
  "Kothrud",
  "Laling",
  "Kalamboli",
  "Belapur",
  "Parabhani",
  "Ahmedabad",
  "Limbe Jalagaon",
  "Bhum",
  "Kamlapur",
  "Dehuli Phata",
  "Shindkheda Raja",
  "Jaykheda, Satana",
  "Dondaicha, Dhule",
  "Wasmat, Hingoli",
  "Nandgaon, Chandrapur",
  "Chinchgavhan Phata",
  "Pimpalgaon Basavant",
  "Pravara Sangam",
  "Lakamapur, Satana",
  "Khamgaon, Buldhana",
  "Belgaon, Karnataka",
  "Sawantwadi, Sindhudurg",
];

const Locations = () => {
  return (
    <div className="locations_page mt-5 pt-5">
      <div className="hero_section even_bg py-5">
        <div className="container">
          <h1>
            “Find Us <span className="single_element">Nationwide!”</span>
          </h1>
          <p>
            Embark on a flavorful journey across India with{" "}
            <span className="single_element">Graduate Chai</span>. From the
            bustling streets of metropolitan cities to the serene landscapes of
            rural towns, our franchise locations are strategically spread across
            the nation, bringing the authentic taste of chai to every corner.
            Whether you're in search of a cozy spot to unwind with a cup of
            Masala Chai or craving the refreshing blend of our Iced Chai on a
            sunny day, you'll find a welcoming ambiance and impeccable service
            at each of our locations. Explore our diverse network of tea stalls
            and experience the essence of Indian tea culture like never before.
          </p>
        </div>
      </div>
      <div className="locations container py-5">
        <div className="row">
          {locations.map((loc, idx) => {
            return <LocationCard loc={loc} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Locations;
