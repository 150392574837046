import React from "react";
import Cup from "../assets/images/about_section.png";

const HomeAboutSection = () => {
  return (
    <div className="homepage_about_section text-center py-5">
      <h1 className="mb-3">
        Shirdi's Most Loved{" "}
        <span className="single_element">Graduate Chai!</span>
      </h1>
      <div className="row">
        <div className="col-md-4">
          <div className="section_image">
            <img src={Cup} alt="" />
          </div>
        </div>
        <div className="col-md-8 px-5">
          <p className="first_para">
            We are an Educated Startup In Tea Industry...
          </p>
          {/* <p>
            Founded in 2018 with our unique and educated taste, We the team of
            Graduates launched our first product "Graduate Masala Tea" with
            special buffalo milk.
          </p> */}
          <p>
            Welcome to <span className="single_element">Graduate Chai</span>,
            where every cup tells a story of India's diverse tea culture. With a
            nationwide presence, our tea stalls bring together the authentic
            flavors of chai, from the snow-capped mountains to the sun-drenched
            coasts. As a franchise-driven venture, we take pride in creating
            local tea experiences while upholding the highest quality standards.
            Join us in celebrating the rich tapestry of Indian tea traditions as
            we continue to brew moments of warmth and connection in every sip.
            Discover the essence of{" "}
            <span className="single_element">Graduate Chai</span>, where
            tradition meets innovation, one chai at a time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
